<template>
  <div>
    <notifications group="notification" position="bottom right" />

    <div class="row">
      <div class="col-12">
        <div
          v-if="hourlyRates.length > 0"
          class="card card-custom gutter-b card-stretch"
        >
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t("MENU.ALL_HOURLY_RATES") }}
                <span v-if="range"
                  >{{ $t("COSTSHEET.FROM_DATE") }}:
                  {{ range.start | formatDate }}</span
                >
                <span v-if="range">
                  {{ $t("COSTSHEET.TO_DATE") }}:
                  {{ range.end | formatDate }}</span
                >
                <span class="text-muted pt-2 font-size-sm d-block">{{
                  $t("LIST.LIST_OF_ALL_HOURLYRATES_IN_DATABASE")
                }}</span>
              </h3>
            </div>
            <div class="card-toolbar">
              <div class="mr-3">
                <v-select
                  class="form-control form-control--construction-select"
                  v-model="selectedConstruction"
                  :options="constructions"
                  :reduce="(construction) => construction['name']"
                  label="name"
                  @input="onConstructionSelected"
                >
                </v-select>
              </div>
              <vc-date-picker
                @input="onRangeSelect"
                is-range
                :columns="2"
                v-model="range"
                :masks="masks"
              >
                <template v-slot="{ inputValue, togglePopover }">
                  <span
                    @click="togglePopover()"
                    class="symbol symbol-40 symbol-light-primary symbol-hover cursor-pointer mr-3 animate__animated animate__fadeIn"
                  >
                    <span class="symbol-label">
                      <span class="svg-icon svg-icon-xl svg-icon-primary">
                        <inline-svg src="media/svg/icons/Media/Playlist2.svg" />
                      </span>
                    </span>
                  </span>
                </template>
              </vc-date-picker>
              <!--begin::Dropdown-->
              <div class="mr-2">
                <button
                  @click.prevent="exportXLSX"
                  type="button"
                  class="btn btn-light-primary font-weight-bolder"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class="svg-icon svg-icon-md">
                    <inline-svg src="media/svg/icons/Files/Export.svg" />
                  </span>
                  Export to .xlsx
                </button>
              </div>
              <!--end::Dropdown-->

              <!--begin::Button-->
              <router-link
                to="/hourly-rates/new"
                class="btn btn-primary font-weight-bolder"
                ><span class="svg-icon svg-icon-md">
                  <inline-svg src="media/svg/icons/Home/Clock.svg" />
                </span>
                {{ $t("GENERAL.NEW") }}</router-link
              >
              <!--end::Button-->
            </div>
          </div>
          <div class="card-body pt-10">
            <div v-if="isHourlyRatesLoaded" class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table
                    class="table table-head-custom table-head-bg table-vertical-center mb-0"
                  >
                    <thead>
                      <tr class="bg-gray-100 text-left">
                        <th style="min-width: 150px" class="pl-7">
                          <span
                            @click="orderProductsByName"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{ 'text-primary': nameOrder.isActive }"
                            >
                              {{ $t("MENU.WORKERS") }}</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': nameOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="nameOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th style="min-width: 120px">
                          <span
                            @click="orderProductsByPrice"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{ 'text-primary': priceOrder.isActive }"
                              >{{ $t("MENU.CONSTRUCTIONS") }}</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': priceOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="priceOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th style="min-width: 120px">
                          {{ $t("GENERAL.DATE") }}
                        </th>
                        <th style="min-width: 120px">
                          {{ $t("GENERAL.WORK_HOURS") }}
                        </th>
                        <th style="min-width: 170px" class="pr-7 text-right">
                          {{ $t("MENU.ACTIONS") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(hourlyRate, key) in hourlyRates" :key="key">
                        <td
                          class="pl-0 py-4 pl-7"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <div class="d-flex align-items-center">
                            <div>
                              <router-link
                                :to="'/hourly-rates/edit/' + hourlyRate.id"
                                class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                                >{{ hourlyRate.worker.firstName }}
                                {{ hourlyRate.worker.lastName }}</router-link
                              >
                              <span class="text-muted font-weight-bold d-block">
                              </span>
                            </div>
                          </div>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span
                            class="font-weight-bolder text-primary cursor-pointer mb-0"
                            >{{ hourlyRate.construction.name }}</span
                          >
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span>{{ hourlyRate.date | formatDate }}</span>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span v-if="hourlyRate.workHours"
                            >{{ hourlyRate.workHours }}h</span
                          >
                          <span v-else>-</span>
                        </td>
                        <td
                          class="pr-7 text-right"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <a
                            href="#"
                            class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                            @click.prevent="showConstruction(hourlyRate.id)"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/General/Visible.svg"
                                title="View"
                                aria-label="View"
                              />
                            </span>
                          </a>
                          <router-link
                            :to="'/hourly-rates/edit/' + hourlyRate.id"
                            class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/Design/Edit.svg"
                                title="Edit"
                                aria-label="Edit"
                              />
                            </span>
                          </router-link>
                          <button
                            @click="removeConstruction(hourlyRate.id)"
                            class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/General/Trash.svg"
                              />
                            </span>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div v-else class="row">
              <div class="col-md-12 text-center">
                <b-spinner variant="primary" type="grow"></b-spinner>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="row text-center">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12 text-center">
                <b-spinner variant="primary" type="grow"></b-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import vSelect from "vue-select";
import { format } from "date-fns";

export default {
  name: "ListConstructions",
  components: {vSelect },
  data() {
    return {
      hourlyRates: [],
      isHourlyRatesLoaded: false,
      constructions: [],
      isConstructionsLoaded: false,
      selectedConstruction: "Sva gradilišta",
      priceOrder: {
        query: "asc",
        isActive: false,
      },
      nameOrder: {
        query: "asc",
        isActive: false,
      },
      selectedHourlyRate: null,
      isSelectedHourlyRateLoaded: false,
      masks: {
        input: "DD.MM:YYYY.",
      },
      range: null,
      appPath: process.env.VUE_APP_ROUTE_API_URL,
      isLoading: false,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "All Hourly Rates", route: "/hourly-rates/list" },
    ]);

    ApiService.get("hourly_rates").then(({ data }) => {
      this.hourlyRates = data["hydra:member"];
      this.isHourlyRatesLoaded = true;

      ApiService.get("constructions").then(({ data }) => {
        this.constructions = data["hydra:member"];
        this.isConstructionsLoaded = true;

        this.constructions.push({
          "@id": "0",
          name: "Sva gradilišta",
        });
      });
    });
  },
  methods: {
    onConstructionSelected(payload) {
      this.isHourlyRatesLoaded = false;
      this.hourlyRates = [];

      if (payload === "Sva gradilišta") {
        ApiService.get("hourly_rates").then(({ data }) => {
          this.hourlyRates = data["hydra:member"];
          this.isHourlyRatesLoaded = true;
        });
      } else {
        ApiService.get(`hourly_rates?construction.name=${payload}`).then(
          ({ data }) => {
            this.hourlyRates = data["hydra:member"];
            this.isHourlyRatesLoaded = true;
          }
        );
      }
    },
    onRangeSelect(payload) {
      this.isHourlyRatesLoaded = false;
      ApiService.get(
        `hourly_rates?date[after]=${format(
          payload.start,
          "yyyy-MM-dd"
        )}&date[before]=${format(payload.end, "yyyy-MM-dd")}`
      ).then(({ data }) => {
        this.hourlyRates = data["hydra:member"];
        this.isHourlyRatesLoaded = true;
      });
    },
    exportXLSX() {
      ApiService.get("hourly_rates/export").then(({ data }) => {
        if (data.status === "ok") {
          this.$notify({
            group: "notification",
            type: "success",
            title: this.$t("HOURLYRATE.EXPORT"),
          });
          window.open(data.downloadUrl, "_blank").focus();
        } else {
          this.$notify({
            group: "notification",
            type: "error",
            title: this.$t("HOURLYRATE.EXPORT_ERR"),
          });
        }
      });
    },
    orderProductsByPrice() {},
    orderProductsByName() {},
    showConstruction(id) {
      this.isSelectedConstructionLoaded = false;
      ApiService.get("constructions/" + id).then(({ data }) => {
        this.selectedConstruction = data;
        this.isSelectedConstructionLoaded = true;
        this.$refs["showModal"].show();
      });
    },
    removeConstruction(id) {
      const confirmation = confirm(this.$t("CONSTRUCTIONS.DEL_CONFIRM"));
      if (confirmation === false) return;

      ApiService.delete("constructions/" + id).then(({ status }) => {
        if (status === 204) {
          this.constructions = this.constructions.filter(
            (construction) => construction.id !== id
          );
          this.$notify({
            group: "notification",
            type: "error",
            title: this.$t("CONSTRUCTIONS.DELETED"),
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
.form-control {
  &--construction-select {
    min-width: 200px;
  }
}
</style>
